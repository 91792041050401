import { useLazyQuery } from "@apollo/client";
import { TRACK_ADD_LISTING } from "../../utils/trackEvent";
import { TOKEN_REDIRECT_PANEL } from "../../action/user";
import { useEffect } from "react";

export default function AddListingRecommendation() {
  const [getToken] = useLazyQuery(TOKEN_REDIRECT_PANEL, {
    onCompleted: (resp) => {
      window.open(
        `https://panel.rumahdewi.com/login/${resp.token_redirect_panel}`
      );
    },
    onError: (resp) => {
      if (resp.message === "Unauthenticated") {
        window.open("https://panel.rumahdewi.com/listing-saya");
      }
    },
  });

  function onAddListingClick() {
    TRACK_ADD_LISTING();

    getToken();
  }

  return (
    <div className="container mt-2">
      <div className="card header-add-listing-bg-img">
        <div className="card-body p-3 m-3 add-listing-bg">
          <div>
            <h2 className="mb-3 text-white text-add-listing">
              Punya Properti untuk Dijual/Disewakan?
            </h2>
            <button
              onClick={onAddListingClick}
              className="btn btn-warning text-white rounded-pill px-3 text-decoration-none"
              target="_blank"
            >
              Pasang Listing Anda
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
