import React from "react";
import CurrencyFormat from "../../common/CurrencyFormat";
import { useRouter } from "next/router";
import { useStateContext } from "../../../context";
import { useCookie } from "next-cookie";
import InformationCircleIcon from "@heroicons/react/solid/InformationCircleIcon";
import Image from "next/image";

const PackageCard = (props) => {
  const {
    detail = false,
    data,
    color = "secondary",
    loading,
    hideButton,
    token = null,
  } = props;

  const router = useRouter();
  const [state, setState] = useStateContext();
  const cookie = useCookie();
  const lat = cookie.get("lat") || router.query.lat || null;
  const lng = cookie.get("lng") || router.query.lng || null;
  const allowLocation = (lat && lng) !== undefined;

  const loggedInUser = state.user;

  const rawData = detail
    ? data
    : data.package_price.length !== 0 && data.package_price[0];
  const packages = detail
    ? rawData.package_list.package_feature
    : data.package_feature;
  const packagePrice = !allowLocation
    ? rawData.selling_info.price
    : rawData.package_state.length === 0
    ? rawData.selling_info.price
    : rawData.package_state[0].special_price;
  const handleCheckout = (id) => {
    const isLogin = !!loggedInUser;
    if (isLogin) {
      router.push(`/packages/${id}/checkout${token ? `?token=${token}` : ""}`);
    } else {
      setState({ ...state, loginModal: true });
    }
  };

  return (
    <div
      className={`package-card h-100 d-flex ${
        color === "primary" && "package-card-primary"
      } ${loading && "placeholder"}`}
    >
      <div className="flex-grow-1 d-flex flex-column justify-content-between">
        <div>
          <div className="text-start d-flex flex-column flex-grow-1 justify-content-between">
            <div className="d-flex flex-column gap-2">
              <div
                className={`${
                  color === "primary"
                    ? "package-card-header-primary"
                    : "package-card-header"
                } p-3`}
              >
                <div className="d-flex justify-content-between align-items-start">
                  <div
                    className={`${
                      color === "primary" ? "text-white" : "text-secondary"
                    } d-flex gap-2 align-items-center`}
                  >
                    <Image
                      src={`/assets/${
                        detail
                          ? data.package_list.name.toLowerCase()
                          : data.name.toLowerCase()
                      }${detail ? "-white" : ""}.svg`}
                      alt="package icon"
                      width={20}
                      height={20}
                    />
                    <h3 className="h4 mb-0">
                      {detail ? data.package_list.name : data.name}
                    </h3>
                  </div>

                  <div className="d-flex flex-column align-items-end">
                    <div
                      className={`${
                        color === "primary" ? "text-white" : "text-primary"
                      } text-end d-flex align-items-end`}
                    >
                      <p className="h4 mb-0 fw-bold">
                        {packagePrice <= 0 ? (
                          "Free"
                        ) : (
                          <CurrencyFormat currency="" amount={packagePrice} />
                        )}
                        /
                      </p>
                      <p className="small-secondary-text mb-0">
                        {rawData.duration_type === 0
                          ? "1 Bulan"
                          : rawData.duration_type === 1
                          ? "6 Bulan"
                          : rawData.duration_type === 2
                          ? "1 Tahun"
                          : "N/A"}
                      </p>
                    </div>

                    {rawData.price !== rawData.selling_info.price && (
                      <div
                        className={`${
                          color === "primary" ? "text-white" : "text-primary"
                        } text-end d-flex align-items-end text-decoration-line-through`}
                      >
                        <p className="small-secondary-text mb-0 fw-bold">
                          {packagePrice <= 0 ? (
                            "Free"
                          ) : (
                            <CurrencyFormat
                              currency=""
                              amount={rawData.price}
                            />
                          )}
                          /
                        </p>
                        <p className="small-secondary-text mb-0">
                          {rawData.duration_type === 0
                            ? "1 Bulan"
                            : rawData.duration_type === 1
                            ? "6 Bulan"
                            : rawData.duration_type === 2
                            ? "1 Tahun"
                            : "N/A"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`p-3 d-flex flex-column ${
                  color === "primary" ? "text-white" : "text-secondary"
                }`}
              >
                {!hideButton && (
                  <p>
                    {rawData.duration_type === 0
                      ? "Sebulan"
                      : rawData.duration_type === 1
                      ? "Bulanan"
                      : rawData.duration_type === 2
                      ? "Tahunan"
                      : "N/A"}
                  </p>
                )}

                <ul className="list-unstyled">
                  {packages.map((pkg, i) => (
                    <li key={i}>
                      <p className={`${!hideButton && "h5"} mb-3`}>
                        {pkg.value === 0 ? "Unlimited" : pkg.value} {pkg.name}
                      </p>
                    </li>
                  ))}
                </ul>

                {!hideButton && (
                  <>
                    <div className="mt-3 alert alert-primary text-secondary d-flex gap-2 align-items-center">
                      <InformationCircleIcon width={16} height={16} />
                      <div className="small-secondary-text">
                        Durasi Listing Sesuai dengan Masa Berlaku Paket
                      </div>
                    </div>

                    <div className="d-flex flex-column gap-2">
                      {data.package_price.map((pkg, i) => (
                        <button
                          className={`btn rounded-pill ${
                            color === "primary"
                              ? i === 0
                                ? "btn-warning"
                                : "btn-outline-warning"
                              : i === 0
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                          onClick={() => handleCheckout(pkg.id)}
                          disabled={pkg.is_active}
                        >
                          {i === 0 ? (
                            "Beli Sekarang"
                          ) : (
                            <span className="d-flex flex-column">
                              <span>
                                {pkg.selling_info.price.toLocaleString("id", {
                                  style: "currency",
                                  currency: "IDR",
                                  maximumFractionDigits: 0,
                                })}
                                /
                                {pkg.duration_type === 0
                                  ? "1 Bulan"
                                  : pkg.duration_type === 1
                                  ? "6 Bulan"
                                  : pkg.duration_type === 2
                                  ? "1 Tahun"
                                  : "N/A"}
                              </span>

                              {pkg.price !== pkg.selling_info.price && (
                                <span className="text-decoration-line-through small-secondary-text">
                                  {pkg.price.toLocaleString("id", {
                                    style: "currency",
                                    currency: "IDR",
                                    maximumFractionDigits: 0,
                                  })}
                                  /
                                  {pkg.duration_type === 0
                                    ? "1 Bulan"
                                    : pkg.duration_type === 1
                                    ? "6 Bulan"
                                    : pkg.duration_type === 2
                                    ? "1 Tahun"
                                    : "N/A"}
                                </span>
                              )}
                            </span>
                          )}
                        </button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
