import React from "react";
import Image from "next/image";
import Link from "next/link";

const Categories = () => {
  const categories = [
    { icon: "/category/rumah-new.svg", label: "Rumah" },
    { icon: "/category/ruko-new.svg", label: "Ruko" },
    { icon: "/category/apartemen-new.svg", label: "Apartemen" },
    { icon: "/category/villa-new.svg", label: "Villa" },
    { icon: "/category/tanah-new.svg", label: "Tanah" },
  ];
  return (
    <div className="w-100">
      <div className="mt-3 mx-auto d-flex flex-wrap justify-content-between">
        {categories.map(function (row, index) {
          return (
            <div key={index} className="text-center">
              <Link
                className="bg-white rounded d-block text-decoration-none"
                href={`/listing?property=${row.label}`}
              >
                <a className="text-secondary">
                  <div
                    className="bg-primary bg-opacity-25 p-2 mx-auto rounded-circle d-flex align-items-center justify-content-center"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <Image
                      src={row.icon}
                      width="24"
                      height="24"
                      alt={row.label}
                    />
                  </div>

                  <div
                    className="text-capitalize"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                    }}
                  >
                    {row.label}
                  </div>
                </a>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
