import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Button } from "react-bootstrap";

const BlogSection = ({ data }) => {
  const headPost = data[0] || {
    attachment: ["/assets/no-image.png"],
    url: "/",
    category: "asd",
  };
  return (
    <div className="container d-flex flex-column gap-4">
      <h2 className="card-title mb-0">Artikel</h2>
      <h3 className="card-title h6 mb-0">
        Kumpulan Artikel Menarik Seputar Dunia Properti
      </h3>

      <div className="row g-4">
        <div className="col-12 col-md-6">
          <div
            className="position-relative rounded-2 overflow-hidden w-100 img-lazy"
            style={{ height: "400px" }}
          >
            <Image
              src={headPost.attachment[0]}
              alt={headPost.post_title}
              layout="fill"
              placeholder="blur"
              blurDataURL={headPost.attachment[0]}
              objectFit="cover"
              unoptimized
            />

            <div className="bg-dark bg-opacity-50 cursor-pointer position-absolute d-flex flex-column gap-2 px-4 py-2 bottom-0 text-white">
              <Link
                href={`https://blog.rumahdewi.com/category/${headPost.category
                  .toLowerCase()
                  .replace("&", "dan")
                  .split(" ")
                  .join("-")}`}
              >
                <div className="cursor-pointer d-flex">
                  <div className="bg-info mw-100 p-2 small-secondary-text">
                    {headPost.category}
                  </div>
                </div>
              </Link>

              <Link href={headPost.url}>
                <h5 className="fs-5 fw-bold">{headPost.post_title}</h5>
              </Link>

              <div className="d-flex gap-2 small">
                <i className="bi bi-clock"></i>

                <p>
                  {new Date(headPost.post_date).toLocaleString("id", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex flex-column align-items-center gap-3">
          {data.slice(1).map((news, i) => (
            <div key={i} className="row w-100 align-items-center">
              <div
                className="col-4 position-relative rounded-2 overflow-hidden img-lazy"
                style={{
                  height: "120px",
                }}
              >
                {news.attachment.length > 0 && (
                  <Image
                    src={news.attachment[0]}
                    alt={news.post_title}
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={news.attachment[0]}
                    objectFit="cover"
                    unoptimized
                  />
                )}
              </div>

              <div className="col-8 d-flex flex-column text-secondary">
                <Link
                  href={`https://blog.rumahdewi.com/category/${news.category
                    .toLowerCase()
                    .replace("&", "dan")
                    .split(" ")
                    .join("-")}`}
                >
                  <div className="cursor-pointer text-info mw-100 small-secondary-text fw-bold">
                    {news.category}
                  </div>
                </Link>

                <h5 className="fs-6">{news.post_title}</h5>

                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 small-secondary-text">
                    <i className="bi bi-clock"></i>

                    <p>
                      {new Date(news.post_date).toLocaleString("id", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      })}
                    </p>
                  </div>

                  <Link href={news.url}>
                    <p className="cursor-pointer text-primary small-secondary-text">
                      Lihat Selengkapnya
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mx-auto">
        <Link href="https://blog.rumahdewi.com">
          <Button variant="outline-primary">Lihat Semua</Button>
        </Link>
      </div>
    </div>
  );
};

export default BlogSection;
