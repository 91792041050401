import React, { useEffect, useState } from "react";
import SearchListingBar from "../common/SearchListingBar";
import HeaderSearch from "../common/HeaderSearch";
import Categories from "./Categories";
import SearchIcon from "@heroicons/react/solid/SearchIcon";
import XCircleIcon from "@heroicons/react/solid/XCircleIcon";
import { useRouter } from "next/router";
import { useStateContext } from "../../context";
import DropdownSearch from "../common/DropdownSearch";

const CategoryList = () => {
  const router = useRouter();

  const [state] = useStateContext();

  const [lastSearched, setLastSearched] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("lastSearched"));
    setLastSearched(items);
  }, []);

  const handleRouterParam = (value) => {
    router.query.state = value;

    let items = JSON.parse(localStorage.getItem("lastSearched")) || [];
    items.unshift(value);

    localStorage.setItem("lastSearched", JSON.stringify(items));

    router.query.property = state.property === "" ? null : state.property;
    router.query.minPrice = state.minPrice === 0 ? null : state.minPrice;
    router.query.maxPrice = state.maxPrice === 0 ? null : state.maxPrice;
    router.query.bedroom = state.bed === 0 ? null : state.bed;
    router.query.bathroom = state.bath === 0 ? null : state.bath;
    router.query.floor = state.floor === 0 ? null : state.floor;
    router.query.minBuild = state.minBuild === 0 ? null : state.minBuild;
    router.query.maxBuild = state.maxBuild === 0 ? null : state.maxBuild;
    router.query.districtName =
      state.districtName === "" ? null : state.districtName;
    var str = "";
    for (var key in router.query) {
      if (router.query[key] !== null) {
        if (key !== "slug") {
          if (str != "") {
            str += "&";
          }
          str += key + "=" + encodeURIComponent(router.query[key]);
        }
      }
    }

    router.push(`/listing?${str}`);
  };

  return (
    <div className="header-bg-img py-3">
      <div>
        <HeaderSearch isLanding={true} />
        <div className=" d-flex align-items-center height-res">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-4 d-flex flex-column ">
                <span
                  className="text-uppercase text-secondary-mobile mb-0  d-none d-md-block"
                  style={{ fontSize: "13px" }}
                >
                  rumahdewi
                </span>
                <h1 className="text-secondary-mobile f-3 d-none d-md-block">
                  Cari <span className="text-primary">Properti</span> Idaman
                  Anda <span className="text-primary">Disini!</span>
                </h1>
                <SearchListingBar isLanding={true} />

                <div className="d-none d-md-block">
                  <Categories />
                </div>

                {lastSearched !== null && (
                  <div>
                    <p className="text-light">Pencarian Terakhir :</p>
                    <div className="d-flex gap-2">
                      {lastSearched?.slice(0, 3).map((item) => (
                        <div
                          className="d-flex gap-2 align-items-center text-light rounded-pill border-3 border border-white p-2 bg-white bg-opacity-25"
                          style={{ width: "100px" }}
                          onClick={() => handleRouterParam(item)}
                        >
                          <div className="w-25">
                            <SearchIcon width={20} height={20} />
                          </div>
                          <p className="mb-0 fs-6 text-truncate">{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
